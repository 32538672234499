body {
  margin: 0;
}
html,body {
  height:100%;
}

body {
  background: #f5f5f5;
  overflow-x: hidden;
}

* {
  font-family: 'Inter';
}

h1, h2, h3, h4, h5, h6, p {
  margin: 0;
}

.centeredHorizontally {
  display: flex;
  justify-content: center;
  align-items: center;
}